import React from 'react'
import useMedia from 'use-media'

// @ts-ignore
import Logo from '../images/kunafa.png'
// @ts-ignore
import LogoBlack from '../images/kunafa-black.png'
// @ts-ignore
import CardanoLogo from '../images/Cardano-White.png'
// @ts-ignore
import CardanoBlackLogo from '../images/Cardano-Black.png'
import {useHeaderColor} from '../hooks/useLogoChanger'

const Navigation: React.FC = () => {
  const {color, leftColor, rightColor} = useHeaderColor()
  const isDesktop = useMedia({minWidth: '1024px'})

  const getLogo = () => {
    if (isDesktop) {
      return leftColor === 'light' ? Logo : LogoBlack
    }

    return color === 'light' ? Logo : LogoBlack
  }

  const getCardanoLogo = () => {
    if (isDesktop) {
      return leftColor === 'light' ? CardanoLogo : CardanoBlackLogo
    }

    return color === 'light' ? CardanoLogo : CardanoBlackLogo
  }

  const getLeftColor = () => {
    if (isDesktop) {
      return leftColor
    }

    return color
  }

  const getRightColor = () => {
    if (isDesktop) {
      return rightColor
    }

    return color
  }

  return (
    <nav
      className="fixed w-screen top-0 left-0 p-6 z-20 flex items-center justify-between"
      id="header"
    >
      <div className="flex items-center md:items-end">
        <img src={getLogo()} className="h-8 md:h-10" />
        <div className="flex flex-col items-start md:flex-row md:items-center ml-6">
          <span
            className={`block ${
              getLeftColor() === 'light' ? 'text-white' : 'text-black'
            } mr-2 text-xs md:text-xs`}
          >
            Powered by
          </span>
          <img src={getCardanoLogo()} className="h-4 md:h-4" />
        </div>
      </div>
      <div className="flex items-center">
        <div>
          <a
            href="https://kunafa.art/login"
            target="_blank"
            rel="noopener noreferrer"
            className={`mr-6 hover:underline ${
              getRightColor() === 'light'
                ? 'text-white md:hover:text-gray-200'
                : 'md:text-gray-900 md:hover:text-gray-800'
            }`}
          >
            Mint NFTs
          </a>
          <a
            href="https://kunafa.art/login"
            target="_blank"
            rel="noopener noreferrer"
            className={`mr-6 hover:underline ${
              getRightColor() === 'light'
                ? 'text-white md:hover:text-gray-200'
                : 'md:text-gray-900 md:hover:text-gray-800'
            }`}
          >
            Pricing
          </a>
        </div>
        <a
          href="https://kunafa.art/login"
          target="_blank"
          rel="noopener noreferrer"
          className={`px-6 hover:underline ${
            getRightColor() === 'light'
              ? 'text-white md:hover:text-gray-200'
              : 'md:text-gray-900 md:hover:text-gray-800'
          }`}
        >
          Login
        </a>
        <a
          href="https://kunafa.art/signup"
          target="_blank"
          rel="noopener noreferrer"
          className="px-6 py-3 bg-primary-500 rounded text-white hover:text-white hover:bg-primary-600"
        >
          Signup
        </a>
      </div>
    </nav>
  )
}

export default Navigation
