import React, {useState, useContext, useEffect} from 'react'

interface AppContextInterface {
  color: 'light' | 'dark'
  setColor: React.Dispatch<React.SetStateAction<string>>
  leftColor: 'light' | 'dark'
  setLeftColor: React.Dispatch<React.SetStateAction<string>>
  rightColor: 'light' | 'dark'
  setRightColor: React.Dispatch<React.SetStateAction<string>>
}

const ColorContext = React.createContext<AppContextInterface | null>(null)

export const ColorContextProvider: React.FC = ({children}) => {
  const [color, setColor] = useState<'light' | 'dark'>('light')
  const [leftColor, setLeftColor] = useState<'light' | 'dark'>('light')
  const [rightColor, setRightColor] = useState<'light' | 'dark'>('light')

  return (
    <ColorContext.Provider
      value={{
        leftColor,
        setLeftColor,
        rightColor,
        setRightColor,
        color,
        setColor,
      }}
    >
      {children}
    </ColorContext.Provider>
  )
}

export const useHeaderColor = (): any => {
  const {color, leftColor, rightColor} = useContext(ColorContext)

  return {color, leftColor, rightColor}
}

export const useColorChanger: any = ({
  color,
  imageColor,
  wrapperRef,
  imageRef,
  isDesktop = true,
}: {
  color: string
  imageColor: string
  wrapperRef: React.MutableRefObject<HTMLDivElement>
  imageRef: React.MutableRefObject<HTMLDivElement>
  isDesktop: boolean
}): any => {
  const {setColor, setRightColor, setLeftColor} = useContext(ColorContext)

  const listenToScroll: EventListener = (event: Event): void => {
    const headerElement = document.querySelector('#header')
    const HEADER_HEIGHT = headerElement.scrollHeight
    const DIV_HEIGHT = wrapperRef.current.scrollHeight
    const IMAGE_HEIGHT = imageRef.current.scrollHeight
    // @ts-ignore
    const scrollPosition = event.target.scrollingElement.scrollTop
    const topOffset = wrapperRef.current.offsetTop
    const mobileTopOffset = wrapperRef.current.offsetTop + IMAGE_HEIGHT
    // @ts-ignore
    const imageTopOffset = imageRef.current.offsetParent.offsetTop

    if (isDesktop) {
      if (
        scrollPosition > topOffset - HEADER_HEIGHT / 2 &&
        scrollPosition < topOffset + DIV_HEIGHT - HEADER_HEIGHT / 2
      ) {
        setLeftColor(imageColor)
        setRightColor(color)
      }

      return
    }

    if (
      scrollPosition > imageTopOffset - HEADER_HEIGHT / 2 &&
      scrollPosition < imageTopOffset + IMAGE_HEIGHT - HEADER_HEIGHT / 2
    ) {
      setColor(imageColor)
    } else if (
      scrollPosition > mobileTopOffset - HEADER_HEIGHT / 2 &&
      scrollPosition < mobileTopOffset + DIV_HEIGHT - HEADER_HEIGHT / 2
    ) {
      setColor(color)
    }
  }

  useEffect(() => {
    if (wrapperRef.current) {
      listenToScroll(({target: document} as unknown) as Event)
      window.addEventListener('scroll', listenToScroll)
      return () => window.removeEventListener('scroll', listenToScroll)
    }
  }, [color, imageColor, wrapperRef, imageRef, isDesktop])

  return null
}
