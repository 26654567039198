import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import GlobalStyle from '../global-style'
import {ColorContextProvider} from '../hooks/useLogoChanger'
import Navigation from './Navigation'

const LayoutStyle = styled.div`
  ${tw`m-0 w-screen overflow-x-hidden`}
`

const Spacer = styled.div`
  ${tw`flex-1`}
`

const Main = styled.main`
  ${tw`w-full m-auto`}
`

const Layout: React.FC = ({children}) => {
  return (
    <ColorContextProvider>
      <GlobalStyle />
      <LayoutStyle>
        <Navigation />
        <Main>{children}</Main>
        <Spacer />
      </LayoutStyle>
    </ColorContextProvider>
  )
}

export default Layout
